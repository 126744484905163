/* eslint-disable no-console */
import uuid from 'uuid';

export const sessionId = uuid.v4();

let logEntriesToken;

function safeConsoleLog(obj) {
    if (process.env.NODE_ENV === 'development' && typeof console === 'object') {
        switch (obj.level) {
            case 'Error':
                console.log('%cERROR', 'background: #c0392bb9; padding: 2px 5px; color: white;', obj);
                break;

            default:
                console.log('%cLOG', 'background: #2980b9; padding: 2px 5px; color: white;', obj);
        }
    }
}

export function init(options) {
    if (options && options.logEntriesToken) {
        logEntriesToken = options.logEntriesToken;

        window.LE.init({
            token: logEntriesToken,
            ssl: true,
            catchall: true,
        });
    }

    log({
        type: 'SessionStart',
        level: 'Info',
        userAgent: navigator.userAgent,
        sessionId,
        time: new Date().toISOString(),
    });

    window.addEventListener('error', onError);
}

function onError(e) {
    log({
        error: e.message,
        level: 'Error',
    });
}

export function log(obj) {
    const log = {
        ...obj,
        sessionId,
        location: window.location.href,
        level: obj.level || 'Info',
    };

    if (logEntriesToken) {
        window.LE.log(log);
    }

    safeConsoleLog(obj);
}

export function info(message) {
    log({
        message,
        level: 'Info',
    });
}

export function warn(message) {
    log({
        error: message,
        level: 'Warn',
    });
}

export function error(error) {
    let errorMessage;

    if (error.message) {
        errorMessage = error.message;
    } else if (typeof error === 'object') {
        try {
            errorMessage = JSON.stringify(error);
        } catch (e) {
            errorMessage = 'Failed to serialize error';
        }
    } else {
        errorMessage = error;
    }

    const logObj = {
        error: errorMessage,
        level: 'Error',
    };

    if (error && error.stack) {
        logObj.stack = error.stack;
    }

    log(logObj);
}
/* eslint-enable no-console */
