import React from 'react';
import Header from './Header';
import { runScript, checkAuthCookie, registerMicroUi, pathPrefix } from './utils';
import * as singleSpa from 'single-spa';
import ReactDOM from 'react-dom';
import './main.scss';
import * as debug from '../debug';
import { inject, observer } from 'mobx-react';
import * as platform from '../api/platform';
import Navbar from './Navbar';

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
        };
    }

    componentDidMount() {
        this.props.appStore.setEnvVariables().then((res) => {
            const webAuth = this.props.appStore.webAuth(res.data);
            const authCookie = checkAuthCookie();

            webAuth.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    const exdays = 1;
                    const d = new Date();
                    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
                    const expires = 'expires' + d.toUTCString();
                    document.cookie = 'accessToken' + '=' + authResult.accessToken + ';' + expires + ';path=/';
                    document.cookie = 'idToken' + '=' + authResult.idToken + ';' + expires + ';path=/';
                    +'domain=.ivsvc.uk';

                    this.setState({
                        user: authResult.idTokenPayload,
                        showAuthMessage: false,
                    });
                    singleSpa.start();
                }
            });

            if (authCookie) {
                webAuth.client.userInfo(authCookie, (err, user) => {
                    if (err && err.code === 401) {
                        debug.error(err);
                        this.handleLogout();
                    } else {
                        this.setState({
                            user: user,
                        });
                    }
                });
                singleSpa.start();
                this.setState({
                    showAuthMessage: false,
                });
            }

            if (!authCookie) {
                this.setState({
                    showAuthMessage: true,
                });
            }

            window.location.href.search('access_token') !== -1 &&
                history.pushState('', document.title, window.location.pathname);
        });
    }

    handleLogout = () => {
        const envVariables = this.props.appStore.envVariables;

        const webAuth = this.props.appStore.webAuth(envVariables);
        webAuth.logout({
            returnTo: `${envVariables.REDIRECT_URL}/`,
            client_id: envVariables.AUTH0_CLIENT_ID,
        });

        const d = new Date();
        const e = d.toUTCString();
        document.cookie = 'accessToken' + '=' + ';' + 'expires = Thu, 01 Jan 1970 00:00:00 GMT' + ';path=/';
        document.cookie = 'idToken' + '=' + ';' + 'expires = Thu, 01 Jan 1970 00:00:00 GMT' + ';path=/';
    };

    clickHandler = (arg) => {
        singleSpaNavigate(arg);
    };

    render() {
        singleSpa.start();
        return (
            <React.Fragment>
                <div className="main">
                    <Header user={this.state.user} handleLogout={this.handleLogout} />
                    <div>
                        <div className="main__navbar" id="navbar">
                            <Navbar />
                        </div>
                        {this.state.showAuthMessage && (
                            <div className="main__errorMessage">
                                An error occurred. Please log in again.{' '}
                                <button className="main__errorButton" onClick={this.handleLogout}>
                                    Log in
                                </button>
                            </div>
                        )}
                        {/* <div className="main__pageLayout" id="stock-module"></div> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default inject('appStore')(observer(Main));
