import { observable, action, decorate } from 'mobx';
import * as api from '../api/dealerList';


class DealerStore {

    getDealers = (page, pageSize) => {
        this.isDealerListLoading = true;
        return new Promise((resolve, reject) => {
            api.getDealers(page, pageSize)
                .then((response) => {
                    this.isDealerListLoading = false;
                    this.dealerList = response.data.dealerships;
                    this.totalDealerships = response.data.numberOfResults;
                    resolve(response);
                })
                .catch((e) => {
                    this.isDealerListLoading = false;
                    reject(e);
                });
        });
    }

    getDealersWithQuery = (page, pageSize, query) => {
        this.isDealerListLoading = true;
        return new Promise((resolve, reject) => {
            api.getDealersWithQuery(page, pageSize, query)
                .then((response) => {
                    this.isDealerListLoading = false;
                    this.dealerList = response.data.dealerships;
                    this.totalDealerships = response.data.numberOfResults;
                    resolve(response);
                })
                .catch((e) => {
                    this.isDealerListLoading = false;
                    reject(e);
                });
        });
    }
}

export default decorate(DealerStore, {
    dealerList: observable.ref,
    totalDealerships: observable.ref,
    isDealerListLoading: observable,
    getDealers: action,
    getDealersWithQuery: action
});
