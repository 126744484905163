import React from 'react';
import PropTypes from 'prop-types';
import NavbarItem from './NavbarItem';
import './navbar.scss';
import * as singleSpa from "single-spa";
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { getParams } from './utils';
import classnames from 'classnames';

class Navbar extends React.Component {

    componentDidMount() {
        const params = getParams();
        this.props.dealerStore.getDealersWithQuery(1, 1, params.dcode);
    }

    clickHandler = arg => {
        singleSpaNavigate(arg);
    };

    clickHome = () => {
        this.clickHandler('/uk/dealers');
    }

    render() {
        const params = getParams();
        const dealerDetails = this.props.dealerStore && this.props.dealerStore.dealerList && this.props.dealerStore.dealerList[0];

        const outerStyles = classnames(
            'navbar',
            params.dealershipId === 'all' && 'navbar__allDealers'
        );

        return (
            <div className={outerStyles}>
                <div className="navbar__dealerOuter">
                    {params.dealershipId !== 'all' && (
                        <div className="navbar__dcode">
                            {params.dcode}
                        </div>
                    )}
                    <div className="navbar__nameOuter">
                        <div className="navbar__name">
                            {params.dealershipId === 'all' ? (
                                <span>
                                    All Dealerships
                                </span>
                            ) : (
                                <span>
                                    {dealerDetails && dealerDetails.tradingName}
                                </span>
                            )}

                        </div>
                        <button onClick={this.clickHome} className="navbar__changeDealerButton">
                            CHANGE DEALERSHIP
                        </button>
                    </div>
                </div>
                {params.dealershipId !== 'all' && (
                    <div className="navbar__navItemOuter">
                        <NavbarItem
                            title="Stock"
                            linkTo="/uk/stock-module"
                            icon="stock"
                        />
                        <NavbarItem
                            title="Reports"
                            linkTo="/uk/reporting/fafreport"
                            icon="reporting"
                            isDisabled={params.dealershipId === 'all'}
                        />
                        <NavbarItem
                            title="Products"
                            linkTo="/uk/settings"
                            icon="product-settings"
                            isDisabled={params.dealershipId === 'all'}
                        />
                    </div>
                )}
            </div>
        );
    }
}

Navbar.propTypes = {
    clickHome: PropTypes.func,
    dealerStore: PropTypes.object
}

export default withRouter(inject('dealerStore')(observer(Navbar)));
