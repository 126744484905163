import * as singleSpa from 'single-spa';

export const pathPrefix = (prefix) => {
    return function(location) {
        return location.pathname.indexOf(`${prefix}`) === 0;
    };
};

export const eitherPrefix = (p1, p2) => {
    return function() {
        return location.pathname === `${p1}` || location.pathname === `${p2}`;
    };
};

export const allButChosenPrefix = (prefix) => {
    return function() {
        if (location.pathname !== prefix) {
            return function() {
                return location.pathname === '/';
            };
        }
    };
};

export const registerMicroUi = (env, id, prefixType, path, path2, props) => {
    let pref;

    if (prefixType === 'pathPrefix') {
        pref = pathPrefix(path);
    } else if (prefixType === 'eitherPrefix') {
        pref = eitherPrefix(path, path2);
    } else if (prefixType === 'allButChosenPrefix') {
        pref = allButChosenPrefix(path);
    }

    const loadUi = async () => {
        await runScript(env);
        if (id === 'stock-module') {
            return window.app1;
        }
        if (id === 'reporting') {
            return window.app2;
        }
        if (id === 'settings') {
            return window.app3;
        }
        // if (id === 'navbar') {
        //     return window.app4;
        // }
    };

    const registerUi = () => {
        singleSpa.registerApplication(id, loadUi, pref);
    };

    registerUi();
};

export const runScript = async (url) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;

        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);
    });
};

export const checkAuthCookie = () => {
    var name = 'accessToken' + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
};

export const getParams = () => {
    var parseQueryString = function() {
        var str = window.location.search;
        var objURL = {};
        str.replace(
            new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
            function( $0, $1, $2, $3 ){
                objURL[ $1 ] = $3;
            }
        );
        return objURL;
    };
    var params = parseQueryString();
    return params;
}
