import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import AppStore from '../stores/AppStore';
import DealerStore from '../stores/DealerStore';
import * as platform from '../api/platform';
import { registerMicroUi } from './utils';
import App from './App';
import * as singleSpa from 'single-spa';
import '../lib/logentries';
import * as debug from '../debug';
import { createBrowserHistory } from 'history';
import { inject, observer } from 'mobx-react';
const history = createBrowserHistory({
    basename: '/',
});

let appStore = new AppStore();
let dealerStore = new DealerStore();

platform
    .fetchEnvironmentVariables()
    .then((res) => {
        appStore.setEnvVariables(res);
        return res.data;
    })
    .then((res) => {
        appStore.webAuth(res);
        registerMicroUi(res.STOCK_MODULE_UI_ENV, 'stock-module', 'pathPrefix', '/uk/stock-module');
        registerMicroUi(res.REPORTING_UI_ENV, 'reporting', 'pathPrefix', '/uk/reporting');
        registerMicroUi(res.SETTINGS_UI_ENV, 'settings', 'pathPrefix', '/uk/settings');
        // registerMicroUi('http://localhost:1334/', 'navbar', 'pathPrefix', '/uk/dealers')

        debug.init({
            logEntriesToken: res.LOGENTRIES_TOKEN,
        });
    })
    .catch((error) => {
        debug.error(error);
    });

history.listen((location, action) => {
    debug.info(`RouteTransition to ${location.pathname}${location.search}`);
});

class Base extends React.Component {
    componentDidMount() {
        singleSpa.start();
    }

    render() {
        return (
            <Provider appStore={appStore} dealerStore={dealerStore}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        );
    }
}

ReactDOM.render(<Base />, document.getElementById('root'));

export default Base;
