import { observable, action, decorate } from 'mobx';
import * as platform from '../api/platform';
import * as auth0 from 'auth0-js';

class AppStore {
    envVariables = {};

    setEnvVariables = () => {
        return platform.fetchEnvironmentVariables().then(
            action((res) => {
                this.envVariables = res.data;
                return res;
            })
        );
    };

    webAuth = (res) => {
        return new auth0.WebAuth({
            domain: res.AUTH0_DOMAIN,
            clientID: res.AUTH0_CLIENT_ID,
            responseType: 'token id_token',
            scope: 'openid profile email read:lendertoolapi read:stockmoduleapi',
            audience: 'https://lender-tool/api',
            redirectUri: res.REDIRECT_URL,
        });
    };
}

export default decorate(AppStore, {
    webAuth: observable,
    envVariables: observable,
    setEnvVariables: action,
});
