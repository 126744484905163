import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Main from './Main';
import LoginPage from './LoginPage';
import DealerListContainer from './DealerListContainer';

class App extends React.Component {
    render() {
        return (
            <Router>
                <Route exact path="/uk" component={LoginPage} />
                <Route path="/uk/dealers" component={DealerListContainer} />
                <Route path="/uk/stock-module" component={Main} />
                <Route path="/uk/reporting" component={Main} />
                <Route path="/uk/settings" component={Main} />
            </Router>
        );
    }
}

export default App;
