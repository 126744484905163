import axios from 'axios';

export const callApi = (url) => {
    const getCookie = (name) => {
        var value = '; ' + document.cookie;
        var parts = value.split('; ' + name + '=');
        if (parts.length == 2)
            return parts
                .pop()
                .split(';')
                .shift();
    };

    const requestHeaders = {
        AUTHORIZATION: 'Bearer ' + getCookie('accessToken'),
    };
    return axios.get(url, { headers: requestHeaders });
};

export function fetchEnvironmentVariables() {
    // always gets the enivroment json from the base route and appends /uk if not run locally

    const showUk = window.origin.split('/')[0] === 'https:' ? '/uk' : '/uk';

    return callApi(`${window.origin}${showUk}/env.json`);
}
