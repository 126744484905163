import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './pagination.scss';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.updateNumPagesToDisplay = _.debounce(this.updateNumPagesToDisplay, 100);
        this.state = {
            numPagesToDisplay: 0,
        };
    }

    updateNumPagesToDisplay = () => {
        if (this.refs.container) {
            this.setState({
                numPagesToDisplay: this.getNumPagesToDisplay(),
            });
        }
    };

    componentDidMount = () => {
        this.updateNumPagesToDisplay();

        window.addEventListener('resize', this.updateNumPagesToDisplay, false);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateNumPagesToDisplay, false);
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.totalItems !== nextProps.totalItems) {
            this.updateNumPagesToDisplay();
        }
    }

    handleChange(page) {
        return () => {
            this.props.onChange(page);
        };
    }

    //todo split this logic out into reusable component
    getPaginationObject = (totalItems, pageSize, currentPage) => {
        let totalPages = totalItems ? Math.ceil(totalItems / pageSize) : 1;
        if (totalPages >= 50) {
            totalPages = 50;
        }

        var items = [];
        var i;

        let startPageAt;
        let endPageAt;

        if (currentPage < Math.ceil(this.state.numPagesToDisplay / 2)) {
            startPageAt = 1;
        } else {
            startPageAt = Math.max(
                Math.min(
                    currentPage - Math.ceil(this.state.numPagesToDisplay / 2) + 1,
                    totalPages - this.state.numPagesToDisplay + 1
                ),
                1
            );
        }

        if (currentPage > totalPages - Math.ceil(this.state.numPagesToDisplay / 2)) {
            endPageAt = totalPages;
        } else {
            endPageAt = Math.max(
                currentPage + Math.floor(this.state.numPagesToDisplay / 2),
                this.state.numPagesToDisplay
            );
        }

        for (i = startPageAt; i <= endPageAt; i++) {
            items.push({
                page: i,
                isCurrent: i === currentPage,
            });
        }

        return {
            firstPage: 1,
            previousPage: Math.max(1, currentPage - 1),
            pages: items,
            nextPage: Math.min(totalPages, currentPage + 1),
            lastPage: totalPages,
        };
    };

    getMaxItemsForCurrentWidth = () => {
        return Math.floor(this.refs.container.offsetWidth / 42); //42 is width of each box
    };

    getNumPagesToDisplay = () => {
        return Math.min(this.getMaxItemsForCurrentWidth(), 13) - 4; // display 9 pages max, -4 is for << < > >> buttons
    };

    render() {
        var paginationObject = this.getPaginationObject(
            parseInt(this.props.totalItems, 10),
            parseInt(this.props.pageSize, 10),
            parseInt(this.props.currentPage, 10)
        );

        return (
            <div ref="container">
                <ul className="pagination__list">
                    <li className="pagination__li">
                        <button
                            className="pagination__button pagination__firstLast"
                            onClick={this.handleChange(paginationObject.firstPage)}
                            type="button">
                            &laquo;
                        </button>
                    </li>

                    <li className="pagination__li">
                        <button
                            className="pagination__button pagination__prevNext"
                            onClick={this.handleChange(paginationObject.previousPage)}
                            type="button">
                            &lt;
                        </button>
                    </li>

                    {paginationObject.pages.map((item, index) => {
                        return (
                            <li className="pagination__li" key={index}>
                                <button
                                    type="button"
                                    className={item.isCurrent ? 'pagination__currentButton' : 'pagination__button'}
                                    onClick={this.handleChange(item.page)}>
                                    {item.page}
                                </button>
                            </li>
                        );
                    })}

                    <li className="pagination__li">
                        <button
                            className="pagination__button pagination__prevNext"
                            onClick={this.handleChange(paginationObject.nextPage)}
                            type="button">
                            &gt;
                        </button>
                    </li>

                    <li className="pagination__li">
                        <button
                            className="pagination__button pagination__firstLast"
                            onClick={this.handleChange(paginationObject.lastPage)}
                            type="button">
                            &raquo;
                        </button>
                    </li>
                </ul>
            </div>
        );
    }
}

Pagination.propTypes = {
    onChange: PropTypes.func.isRequired,
    totalItems: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Pagination;
