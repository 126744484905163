import React from 'react';
import PropTypes from 'prop-types';
import './navbarItem.scss';
import classnames from 'classnames';
import * as singleSpa from "single-spa";
import { withRouter } from 'react-router-dom';

class NavbarItem extends React.Component {

    clickHandler = arg => {
        singleSpaNavigate(arg);
    };

    handleNavClick = () => {
        this.clickHandler(`${this.props.linkTo}${this.props.location.search}`);
    }

    render() {
        const bottomStyles = classnames(
            'navbarItem__bottomBorder',
            this.props.linkTo === this.props.location.pathname && 'navbarItem__bottomBorder--active'
        );

        return (
            <button onClick={this.handleNavClick} className="navbarItem">
                <div className="navbarItem__inner">
                    <div className={`navbarItem__icon navbarItem__icon--${this.props.icon}`} />
                    <div className="navbarItem__title">
                        {this.props.title}
                    </div>
                </div>
                <div className={bottomStyles} />
            </button>
        );
    }
}

NavbarItem.propTypes = {
    linkTo: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string
};

export default withRouter(NavbarItem);
