import React from 'react';
import PropTypes from 'prop-types';
import './dealerListItem.scss';
import { Link } from 'react-router-dom';

const DealerListitem = ({ dealer, bold }) => {
    const dcode = dealer.motonovoIds && dealer.motonovoIds[0].motonovoId || '';
    return (
        <Link to={`/uk/stock-module?dealershipId=${dealer.id}&dcode=${dcode}`} className="dealerListItem">
            {bold ? <strong>{dealer.companyName}</strong> : <span>{dealer.companyName}</span>}
        </Link>
    );
}

DealerListitem.propTypes = {
    dealer: PropTypes.object,
};

export default DealerListitem;
