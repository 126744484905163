import React from 'react';
import PropTypes from 'prop-types';
import './dealerInput.scss';

class DealerInput extends React.Component {

    render() {
        return (
            <div className="dealerInput">
                <input
                    className="dealerInput__input"
                    placeholder="Search dealerships by D-CODE or name"
                    onChange={this.props.onChange}
                />
            </div>
        );
    }
}

DealerInput.propTypes = {
    onChange: PropTypes.func
};

export default DealerInput;
