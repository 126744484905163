import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './input.scss';

const Input = ({ label, id, type, onChange, defaultValue, onError }) => {
    const inputStyles = classnames('input__input', onError && 'input__error');

    return (
        <div className="input__outer">
            <label className="input__label">{label}</label>
            <input
                key={id}
                className={inputStyles}
                id={id}
                type={type}
                onChange={onChange}
                defaultValue={defaultValue}
            />
        </div>
    );
};

Input.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    onError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Input;
