import React from 'react';
import './loginPage.scss';
import Input from './Input';
import * as singleSpa from 'single-spa';
import { checkAuthCookie } from './utils';
import * as debug from '../debug';
import { inject, observer } from 'mobx-react';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            showForgotPassword: null,
            loginError: null,
            changePasswordMessage: null,
            changePasswordError: null,
        };
    }

    componentDidMount() {
        const d = new Date();
        const e = d.toUTCString();
        document.cookie = 'accessToken' + '=' + ';' + e + ';path=/';
    }

    handleLogin = (loginState) => {
        const envVariables = this.props.appStore.envVariables;
        const webAuth = this.props.appStore.webAuth(envVariables);
        webAuth.login(
            {
                realm: 'lenders',
                username: loginState.username,
                password: loginState.password,
                scope: 'openid profile email read:lendertoolapi read:stockmoduleapi',
                redirectUri: `${envVariables.REDIRECT_URL}/dealers`,
                // redirectUri: `${envVariables.REDIRECT_URL}/stock-module`,
            },
            (e, authResult) => {
                if (e && e.error) {
                    this.setState({
                        loginError: e.error,
                    });
                    debug.error(e.error);
                }
            }
        );

        singleSpa.start();
    };

    handleForgotPassword = (email) => {
        const envVariables = this.props.appStore.envVariables;
        const webAuth = this.props.appStore.webAuth(envVariables);
        webAuth.changePassword(
            {
                connection: 'lenders',
                email: email,
            },
            (err, resp) => {
                if (err) {
                    this.setState({
                        changePasswordMessage: null,
                        changePasswordError: 'Please enter your email address',
                    });
                    debug.error(err);
                } else {
                    this.setState({
                        changePasswordMessage: resp,
                        changePasswordError: null,
                    });
                }
            }
        );
    };

    setValue = (e) => {
        this.setState({
            [e.currentTarget.id]: e.currentTarget.value,
        });
    };

    submit = (e) => {
        e.preventDefault();
        this.handleLogin(this.state);
    };

    toggleForgotPassword = (e) => {
        e.preventDefault();
        this.setState({
            showForgotPassword: !this.state.showForgotPassword,
        });
    };

    submitForgotPassword = (e) => {
        e.preventDefault();
        this.handleForgotPassword(this.state.username);
    };

    render() {
        return (
            <div className="loginPage">
                <div className="loginPage__inner">
                    {this.state.showForgotPassword ? (
                        <form className="loginPage__left" onSubmit={this.submitForgotPassword}>
                            <div className="loginPage__mobileIcon" />
                            <div className="loginPage__title">Forgotten Password</div>
                            <div className="loginPage__subtitle">
                                Please enter the email address you use to log in and we will send you instructions for
                                resetting your password
                            </div>
                            <Input
                                label="Email address"
                                id="username"
                                type="text"
                                onChange={this.setValue}
                                defaultValue={this.state.username}
                                onError={this.state.changePasswordError}
                            />
                            {this.state.changePasswordMessage && (
                                <div className="loginPage__confirmText">{this.state.changePasswordMessage}</div>
                            )}
                            {this.state.changePasswordError && (
                                <div className="loginPage__errorText">An error has occurred, please try again</div>
                            )}
                            <button className="loginPage__submit" type="submit">
                                Send Email
                            </button>
                            <button onClick={this.toggleForgotPassword} className="loginPage__forgot" type="button">
                                back to login page
                            </button>
                        </form>
                    ) : (
                        <form onSubmit={this.submit} className="loginPage__left">
                            <div className="loginPage__mobileIcon" />
                            <div className="loginPage__title">
                                Welcome to <br /> Lender Admin System
                            </div>
                            <Input
                                label="Email address"
                                id="username"
                                type="text"
                                onChange={this.setValue}
                                defaultValue={this.state.username}
                                onError={this.state.loginError}
                            />
                            <Input
                                label="Password"
                                id="password"
                                type="password"
                                onChange={this.setValue}
                                defaultValue={this.state.password}
                                onError={this.state.loginError}
                            />

                            {this.state.loginError && (
                                <div>
                                    {this.state.loginError === 'invalid_request' && (
                                        <div className="loginPage__errorText">
                                            An error has occurred, please try again
                                        </div>
                                    )}
                                    {this.state.loginError === 'access_denied' && (
                                        <div className="loginPage__errorText">Incorrect username or password</div>
                                    )}
                                    {this.state.loginError === 'too_many_attempts' && (
                                        <div className="loginPage__errorText">
                                            Your account has been blocked after multiple consecutive login attempts.
                                            We've sent you an email with instructions on how to unblock it.
                                        </div>
                                    )}
                                </div>
                            )}
                            <button className="loginPage__submit" type="submit">
                                LOG IN
                            </button>
                            <button onClick={this.toggleForgotPassword} className="loginPage__forgot" type="button">
                                forgot your password?
                            </button>
                        </form>
                    )}
                    <div className="loginPage__right">
                        <div className="loginPage__ivendi" />
                    </div>
                </div>
            </div>
        );
    }
}

export default inject('appStore', 'dealerStore')(observer(LoginPage));
