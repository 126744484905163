import React from 'react';
import PropTypes from 'prop-types';
import './dealerListPage.scss';
import DealerInput from './DealerInput';
import DealerListItem from './DealerListItem';
import { inject, observer } from 'mobx-react';
import Pagination from './Pagination';
import LoadingSpinner from './LoadingSpinner';
import _ from 'lodash';

class DealerListPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            pageSize: 10,
        };
    }

    componentDidMount() {
        this.props.dealerStore.getDealers(1, 10);
    }

    handleSearch = (e) => {
        if (_.isEmpty(e.currentTarget.value)) {
            this.props.dealerStore.getDealers(1, 10);
        } else {
            this.props.dealerStore.getDealersWithQuery(1, 10, e.currentTarget.value);
        }
    };

    handlePagination = (pageNum) => {
        this.setState({
            page: pageNum,
        });

        this.props.dealerStore.getDealers(pageNum, this.state.pageSize);
    };

    handleRows = (e) => {
        const pageSize = e.currentTarget.value;
        this.setState({
            pageSize: pageSize,
        });

        this.props.dealerStore.getDealers(1, pageSize);
    };

    render() {
        const dealerStore = this.props.dealerStore;

        const dealerList = dealerStore && dealerStore.dealerList && dealerStore.dealerList;
        const totalDealerships = dealerStore && dealerStore.totalDealerships && dealerStore.totalDealerships;
        const isLoading = dealerStore && dealerStore.isDealerListLoading && dealerStore.isDealerListLoading;

        const rowOptions = [
            { key: '10', value: '10' },
            { key: '12', value: '12' },
            { key: '25', value: '25' },
            { key: '50', value: '50' },
            { key: '100', value: '100' },
            { key: totalDealerships, value: 'show all' },
        ];

        return (
            <div className="dealerListPage">
                <div className="dealerListPage__top">
                    <div className="dealerListPage__header">
                        <div className="dealerListPage__fafLogo"></div>
                        <div className="dealerListPage__headerText">Dealerships</div>
                    </div>
                    <div className="dealerListPage__subHeader">
                        View dealership statistics and identify problems with the FAFMC stock.
                    </div>
                </div>
                <div className="dealerListPage__listSection">
                    <DealerInput onChange={this.handleSearch} />
                    {isLoading ? (
                        <div className="dealerListPage__listLoading">
                            <LoadingSpinner />
                        </div>
                    ) : (
                        <div>
                            <DealerListItem dealer={{ companyName: 'All Dealerships', id: 'all' }} bold={true} />
                            {dealerList && dealerList.map((dealer, i) => <DealerListItem key={i} dealer={dealer} />)}
                        </div>
                    )}

                    <div className="dealerListPage__listBottom">
                        <span className="dealerListPage__selectLabel">Rows per page:</span>
                        <select className="dealerListPage__rowSelect" onChange={this.handleRows}>
                            {rowOptions.map((option, i) => (
                                <option key={i} value={option.key} defaultValue={this.state.pageSize}>
                                    {option.value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <Pagination
                        currentPage={this.state.page}
                        totalItems={totalDealerships}
                        pageSize={this.state.pageSize}
                        onChange={this.handlePagination}
                    />
                </div>
            </div>
        );
    }
}

DealerListPage.propTypes = {};

export default inject('dealerStore')(observer(DealerListPage));
