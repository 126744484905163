import React from 'react';
import PropTypes from 'prop-types';
import './headerMenuItem.scss';

const HeaderMenuItem = ({ onClick, children }) => (
    <div onClick={onClick} className="headerMenuItem">
        {children}
    </div>
);

HeaderMenuItem.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node
};

export default HeaderMenuItem;
