import axios from 'axios';

export const callApi = (url) => {
    const getCookie = (name) => {
        var value = '; ' + document.cookie;
        var parts = value.split('; ' + name + '=');
        if (parts.length == 2)
            return parts
                .pop()
                .split(';')
                .shift();
    };

    const requestHeaders = {
        AUTHORIZATION: 'Bearer ' + getCookie('accessToken'),
    };
    return axios.get(url, { headers: requestHeaders });
};

export function getDealers(page, pageSize) {
    return callApi(`/uk/api/reporting/Dealership/list?page=${page}&pageSize=${pageSize}`);
}

export function getDealersWithQuery(page, pageSize, query) {
    return callApi(`/uk/api/reporting/Dealership/list?page=${page}&pageSize=${pageSize}&query=${query}`);
}
//D141309 &query=motorcycles
