import React from 'react';
import PropTypes from 'prop-types';
import './header.scss';
import * as singleSpa from 'single-spa';
import _isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import HeaderMenuItem from './HeaderMenuItem';
import { Link } from 'react-router-dom';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen: false,
        };
    }

    clickHandler = (arg) => {
        singleSpaNavigate(arg);
    };

    handleMenu = () => {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen,
        });
    };

    handleLogout = () => {
        this.props.handleLogout();
        this.setState({
            isMenuOpen: false,
        });
    };

    render() {
        const user = this.props && this.props.user;
        const initials = `${user && user.given_name && user.given_name.charAt(0)}${user &&
            user.family_name &&
            user.family_name.charAt(0)}`;

        const menuStyles = classnames(
            'header__userDropdownMenu',
            this.state.isMenuOpen && 'header__userDropdownMenu--open'
        );

        const menuButtonStyles = classnames(
            'header__userDropdownArrow',
            this.state.isMenuOpen && 'header__userDropdownArrow--open'
        );

        return (
            <div className="header">
                <Link
                    to="/uk/dealers"
                    className="header__homeLink">
                    <div className="header__imageOuter" />
                </Link>

                {!_isEmpty(this.props.user) && (
                    <div className="header__userSection">
                        <div className="header__userInitials">{initials}</div>
                        <div className="header__userName">
                            {user.given_name} {user.family_name}
                        </div>
                        {/*<button onClick={this.handleMenu} className="header__userDropdownButton">
                            <div className={menuButtonStyles}>{'<'}</div>
                        </button>
                        <div className={menuStyles}>
                            <HeaderMenuItem onClick={this.handleLogout}>
                                Log Out
                            </HeaderMenuItem>

                        </div>*/}
                        <button onClick={this.handleLogout} className="header__logoutButton">
                            Log Out
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

Header.propTypes = {
    handleLogout: PropTypes.func,
    user: PropTypes.object,
};

export default Header;
