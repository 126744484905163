exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../images/stock.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../images/reporting.svg"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../images/product-settings.svg"));

// Module
exports.push([module.id, ".navbarItem{font-size:1.4rem;text-transform:uppercase;margin:0 80px 0 0;background-color:#363d47;transition:0.3s all ease-in-out;cursor:pointer;color:#FFFFFF;border:none;outline:none;padding:0}.navbarItem:hover{background-color:#4C5664;transition:0.3s all ease-in-out}@media (max-width: 560px){.navbarItem{width:33.333%;margin:0}}.navbarItem__inner{display:flex;align-items:center;min-width:80px;padding:10px}.navbarItem__icon{width:25px;height:25px;margin:0 10px 0 0;background-position:center;background-repeat:no-repeat;background-size:contain}@media (max-width: 560px){.navbarItem__icon{display:none}}.navbarItem__icon--stock{background-image:url(" + ___CSS_LOADER_URL___0___ + ")}.navbarItem__icon--reporting{background-image:url(" + ___CSS_LOADER_URL___1___ + ")}.navbarItem__icon--product-settings{background-image:url(" + ___CSS_LOADER_URL___2___ + ")}@media (max-width: 560px){.navbarItem__title{width:100%;text-align:center}}.navbarItem__bottomBorder{width:100%;height:2px;background-color:none}.navbarItem__bottomBorder--active{background-color:#FFFFFF}\n", ""]);

